import * as React from "react"

import Header from '../../components/Header'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const Blog = () => {

  return (
    <Layout>
      <Seo title="Blog" />

      <Header />
      
    </Layout>
  )

};

export default Blog;
